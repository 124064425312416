
$danger:                        $pink;

$body-bg:                       $black;
$body-color:                    $gray-600;
$border-color:                  $gray-800;

$custom-colors: (
  "red": $red-400
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");