/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import "../../node_modules/bootstrap/scss/functions";
@import "variables";
@import "../../node_modules/bootstrap/scss/variables";
@import "variables-maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
//@import "../../node_modules/bootstrap/scss/accordion";
//@import "../../node_modules/bootstrap/scss/breadcrumb";
//@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
//@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
//@import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
//@import "../../node_modules/bootstrap/scss/tooltip";
//@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/offcanvas";
@import "../../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack

.rock-style-logo {
    img {
        max-width: 500px;
        width: 100%;
    }
}

.bg-img-cover {
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.bg-img-straight {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    z-index: 1;
    width: 100%;
}

.carosel-bg-cover {
    background-size: cover;
    opacity: .5;
    filter: blur(15px);
    background-position: center;
}

.carousel-caption {
    background-color: $body-bg;
    left:25%;
    right:25%; 
    border-radius:20px;
    z-index: 1;
}

.card-indicators {
    display: flex;
    .item-indicator {
        height: 1px;
        background-color: $secondary;
        flex-grow: 1;
    }
    .item-indicator:not(:last-child) {
        margin-right: 1px;
    }
    .active {
        background-color: $red-400;
    }
}

.cart-badge {
    margin-left: -10px;
    margin-top: -7px;
    height: 20px;
    width: 20px;
    display: block;
    line-height: 1.8;
    padding: 0;
}

.svg-app-icon {
    svg { 
        fill:inherit;
        height:inherit;
        width:inherit;
        display:block;
        path, rect {
            fill:currentColor;
        }
    }
}